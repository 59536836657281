import React, { Component } from "react"
import css from "./RichTextRender.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class RichTextRender extends Component {
  constructor(props) {
    super(props)

    this.state = {
      titleLoaded: false,
    }
    this.richTextRenderOptions = {
      renderNode: {
        "embedded-asset-block": this.handleRichTextAssets,
      },
    }
  }
  handleRichTextAssets(node) {
    // if (node.data.target.fields.file.contentType.indexOf("image") != -1) {
    //   return <img className="rich-text-asset rich-text-asset-image img-fluid" src={node.data.target.fields.file.url + "?h=1000&q=85"} />
    // } else if (node.data.target.fields.file.contentType.indexOf("application/pdf") != -1) {
    //   return <embed className="rich-text-asset rich-text-asset-pdf" src={node.data.target.fields.file.url} type="application/pdf"></embed>
    // } else if (node.data.target.fields.file.contentType.indexOf("video/mp4") != -1) {
    //   const videoHtml = (
    //     <div className="post-body-video-field">
    //       <ReactPlayer className="rich-text-asset" height={"100%"} url={node.data.target.fields.file.url} controls />
    //     </div>
    //   )
    //   return videoHtml
    // }
  }
  render() {
    return <>{documentToReactComponents(this.props.documentRichText)}</>
  }
}
export default RichTextRender
